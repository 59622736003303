import React from "react"
import { Link } from "gatsby"
import container2 from "../images/container2.jpg"
import Layout from "../components/layout"
import layoutIndex from "./index.scss"
import Head from "../components/head"
import container3 from "../images/container3.jpg"
import blogStyles from "./blog.module.scss" 
import higiene from "../images/higiene.jpg"
import bebida from "../images/bebida.jpg"
import pet from "../images/pet.jpeg"
import bazar from "../images/bazar.jpg"
import limpeza from "../images/limpeza.jpg"
import mercearia from "../images/mercearia.jpg"
import contactStyles from "./contact.module.scss"



const IndexPage = () => {
  return (
    <Layout >
      <Head title="Home" />
      <body>
        <h1>QUALIDEN OF BRAZIL</h1>
        <h2>Quality First</h2>
        <div class="crop">
          <img class="image" src={container2} alt="Container2" />
        </div>
        <p>Here at Qualiden of Brazil, we offer a wide range of products. We ensure that these products are from the highest quality, with the best value for your money. The commitment we establish with an exceptional service allows us to work closely with our customers to guarantee 100% satisfaction.
        </p>
        <p> Browse through our site to obtain more information and feel free to   <Link to="/contact">contact us</Link> if you have any questions.
      </p>
      </body>
      <Head title="About" />
      {/*<Img 
        fluid={data.image.childImageSharp.fluid}
      />*/}
      <h1>ABOUT US</h1>
      <h2>Quality First</h2>
      <img class="image" src={container3} alt="Container3" />
      <p>Here at Qualiden of Brazil, we have dedicated ourselves since 1992 to supply products with the highest quality for the lowest possible price. Our aim is to provide exceptional service, always exceeding our customers expectations. </p>
      <p>We are proud to say that we are seen by our clients as one of the best supplying companies in Brazil. Our reputation stems from continuous effort to improve our range of products and services.</p>
      
      
      <p> Browse through our site to obtain more information and feel free to   <Link to="/contact">contact us</Link> if you have any questions.
      </p>

      <Head title="Produtos" />
      <h1 id="products">PRODUCTS</h1>
      <div className={blogStyles.grid}>
        <div className={blogStyles.card}>
          <figure>
            <Link to="/higiene">
                <img className={blogStyles.image} src={higiene} alt="higiene" />
            </Link>
            <h2>Hygiene</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>  
          <figure>
            <Link to="/bebidas">
                <img className={blogStyles.image} src={bebida} alt="bebida" />
            </Link>
            <h2>Beverages</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
            <Link to="/limpeza">
              <img className={blogStyles.image} src={limpeza} alt="limpeza" />
            </Link>
            <h2>Cleaning</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
            <Link to="/pet">
              <img className={blogStyles.image} src={pet} alt="pet" />
            </Link>
            <h2>Pet</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
            <Link to="/mercearia">
              <img className={blogStyles.image} src={mercearia} alt="mercearia" />
            </Link>
            <h2>Groceries</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
            <Link to="/bazar">
              <img className={blogStyles.image} src={bazar} alt="bazar" />
            </Link>
            <h2>Bazaar</h2>
          </figure>
        </div>
      </div>

      <Head title="Contact" />
      <h1>CONTACT INFORMATION</h1>
      <div>
        <ol className={contactStyles.infos}>
          <li className={contactStyles.post}>
            <h2>PHONE NUMBER</h2>
            <p>
              < a href="https://wa.me/+5511981001712">+55 11 98100-1712</a>
            </p>
            <h2>E-MAIL</h2>
            <p>
              < a href="mailto:export@qualiden.com.br">export@qualiden.com.br</a>
            </p>
          </li>
        </ol> 
      </div>
    </Layout>
    
  )
}

export default IndexPage